//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-616:_9992,_1400,_6014,_4824,_4496,_8171,_1448,_7248;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-616')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-616', "_9992,_1400,_6014,_4824,_4496,_8171,_1448,_7248");
        }
      }catch (ex) {
        console.error(ex);
      }